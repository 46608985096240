@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.App {
  text-align: center;
  animation: slideUp 2.5s ease; 
  margin-top: -220px;
}

.Photo{
  position: relative;
}

.Foto {
  width: 300px;
  height: 300px;
  border-radius: 5%;
  border: solid 2px white;
  margin: 50px;
}

.Icons{
  border: solid 1px rgb(196, 227, 18);
  margin: 10px;
  border-radius: 5px;
  padding: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Icons:active {
  background-color: lightgrey; 
}

.Icons .iconify {
  font-size: 2rem; 
  cursor: pointer;
}

.whatsapp{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whatsapp-number {
  font-size: 12px;
}

.Footer{
  font-size: 15px;
}

.Text {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .Text {
    font-size: 1.2rem; 
  }
}

.Background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 220px; 
  background-color: black; 
  z-index: 1; 
  animation: slideUp 2.5s ease;
}


.App,
.Photo,
.Text,
.Socials,
.Footer {
  position: relative;
  z-index: 2; 
}